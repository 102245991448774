/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}
@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes header_index_show {
	0% {
		display: none;
	}
	1% {
		display: block;
		opacity: 0;
		top: -30px;
	}
	100% {
		top: 0;
		opacity: 1;
		display: block;
	}
}
@keyframes header_index_hide {
	0% {
		top: 0;
		opacity: 1;
		display: block;
	}
	99% {
		top: -30px;
		opacity: 0;
		display: block;
	}
	100% {
		top: -30px;
		opacity: 0;
		display: none;
	}
}
@keyframes header_search_show {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(64px);
	}
}
@keyframes header_search_hide {
	0% {
		transform: translateY(64px);
	}
	100% {
		transform: translateY(0);
	}
}
.hero_area .hero {
	opacity: 0;
	animation-name: _on_opacity;
	animation-duration: 1s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: 0s;
}
.hero_area-init .hero {
	animation-name: none;
}
@charset "utf-8";
@import 'import/var';
@import 'import/animation';

.hero_area {
	.hero {
		opacity: 0;
		animation-name: _on_opacity;
		animation-duration: 1s;
		animation-timing-function: $easeOutCubic;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		animation-delay: 0s;
	}

	&-init {
		.hero {
			animation-name: none;
		}
	}
}

